import React, { useMemo } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import { useFiltersData } from '../../hooks/useFiltersData';
import Loading from '../../../../ui/Loading';
import GridRow from '../../../../ui/GridRow';
import ToggleButton from '../../../../ui/ToggleButton/ToggleButton';
import GridCol from '../../../../ui/GridCol';
import { GridAlignEnum } from '../../../../ui/GridRow/GridRow';

const CategoryFilters = ({ control }: { control: Control }) => {
    const filtersData = useFiltersData();
    const category = useWatch({ name: 'category', control });
    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        name: 'filterItemList',
    });

    const filteredItems = useMemo(() => {
        if (!filtersData || !category) return [];

        return filtersData.filter((item) => item.categories.includes(category.id));
    }, [filtersData, category]);

    if (!filtersData) return <Loading />;

    const getValue = (id: number) => {
        return fields.some((item) => item.id === id);
    };

    const handleSelect = (state, changedItem) => {
        if (state) {
            append(changedItem);
        } else {
            // eslint-disable-next-line array-callback-return
            const indexToRemove = fields.findIndex((item) => item.id === changedItem.id);
            if (indexToRemove >= 0) {
                remove(indexToRemove);
            }
        }
    };

    return (
        <GridRow gutter={8} alignRow={GridAlignEnum.TOP}>
            {filteredItems.map((group) => {
                return (
                    <GridCol col={3} key={group.id}>
                        <GridRow column gutter={4}>
                            <p>{group.name}</p>
                            {group.items.map((item) => {
                                return (
                                    <ToggleButton
                                        key={item.id}
                                        value={getValue(item.id)}
                                        onClick={(state) => {
                                            handleSelect(state, item);
                                        }}
                                        label={item.name}
                                    />
                                );
                            })}
                        </GridRow>
                    </GridCol>
                );
            })}
        </GridRow>
    );
};

export default CategoryFilters;
